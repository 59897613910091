import React from "react";
import './index.css';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="site-footer">
        <div className="footer-content-container">
          <div className="footer-left-container">
            <div className="footer-tm-container main-text">
              <span className="footer-tm-text">AFFORD NYC</span><span className="footer-tm">TM</span>
            </div>
            <div className="footer-copyright-container footer-sub-text sub-text">
              &copy; Afford NYC. {(new Date()).getFullYear()}.
              <br />
              All Terms Apply.
            </div>
          </div>
          <div className="footer-right-container">
            <div className="footer-contact-us-text main-text">
              Contact Us:
            </div>
            <a href="mailto:iWill@afford.nyc?subject=I want to Afford NYC!" className="footer-contact-us-email footer-sub-text sub-text">
              iWill@afford.nyc
            </a>
          </div>
        </div>
      </div>
    );
  }
}
