import React from "react";
import './index.css';

export default class MonitorModule extends React.Component {
  render() {
    return (
      <div className="module-wrapper dream-neighborhood-module">
        <div className="module-container dream-neighborhood-module-container">
          <div className="dream-neighborhood-floating-cube" />
          <div className="dream-neighborhood-module-text">
            <div className="main-text dream-neighborhood-module-main-text">
                It’s hard to find somewhere you want to stay for a long time in NYC
            </div>
            <div className="sub-text dream-neighborhood-module-sub-text">
                Make your search easier by automatically viewing affordable apartments in your dream neighborhood. 
            </div>
          </div>
        </div>
      </div>
    );
  }
}
