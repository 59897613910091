import React from "react";
import './index.css';

export default class ArrowUp extends React.Component {
  render() {
    return (
      <div className="arrow-up-wrapper">
        <div className="arrow-up" />  
      </div>   
    );
  }
}
