import React from "react";
import './index.css';
import InstallButton from '../../../../pageComponent/InstallButton';

export default class MonitorModule extends React.Component {
  render() {
    return (
      <div className="module-wrapper spend-and-save-module">
        <div className="module-container spend-and-save-module-container">
          <div className="spend-and-save-floating-circle" />
          <div className="spend-and-save-module-text">
            <div className="main-text spend-and-save-module-main-text">
                Spend $9, Get Years of Savings
            </div>
            <div className="sub-text spend-and-save-module-sub-text">
                Living in a rent stabilized apartment can save you thousands every year. Invest in yourself now, so you can save more later.
            </div>
            <InstallButton className="spend-and-save-module-install-button" />
          </div>
        </div>
      </div>
    );
  }
}
