import React from "react";
import './index.css';
import { extensionIsInstalled, listenForExtensionInstalled } from '../../utils/extensionInstalledCheck';
import { checkIsMobile, checkIsChrome } from '../../utils/userAgentParsing';
import chromeExtensionUrl from '../../utils/chromeExtensionUrl';


export default class InstallButton extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      hasExtension: extensionIsInstalled(),
      isMobile: false,
      isChrome: true
    };
  }

  componentDidMount() {
    const hasExtension = extensionIsInstalled();
    const isMobile = checkIsMobile();
    const isChrome = checkIsChrome();
    if (this.state.isMobile !== isMobile || this.state.isChrome !== isChrome || this.state.hasExtension !== hasExtension) {
      setTimeout(() => {
        this.setState({
          hasExtension,
          isMobile,
          isChrome
        });
      });
    }
    if (!this.state.hasExtension) {
      this.unlisten = listenForExtensionInstalled(() => this.setState({ hasExtension: true }));
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const {
      hasExtension,
      isMobile,
      isChrome
    } = this.state;

    if (hasExtension) {
      return (
        <a href={chromeExtensionUrl} className={`${this.props.className || ''} install-button`}>
          Happy Hunting!
        </a>
      );
    }

    if (isMobile) {
      // all mobile
      return (
        <a href={chromeExtensionUrl} className={`${this.props.className || ''} install-button install-button-unavailable`}>
          Available for Chrome Desktop
        </a>
      );
    } else if (!isChrome) {
      // non-Chrome desktop
      return (
        <a href={chromeExtensionUrl} className={`${this.props.className || ''} install-button install-button-unavailable`}>
          Available for Chrome
        </a>
      );
    }

    // Chrome desktop
    return (
      <a href={chromeExtensionUrl} className={`${this.props.className || ''} install-button`}>
        Install Now
      </a>
    );
  }
}
