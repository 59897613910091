import React from "react";
import MacWithImage from '../../../static/MacWithImage.png';
import Logo from '../../../static/logos/logo.svg';
import './index.css';

export default class MonitorModule extends React.Component {
  render() {
    return (
      <div className="mac-with-screenshot">
        <img className="mac-with-screenshot-image" src={MacWithImage} alt="Afford NYC Screenshot" />
        <div className="enlarged-logo-container">
          <img className="enlarged-logo" src={Logo} alt="Afford NYC Logo" />
        </div>
      </div>
    );
  }
}
