import React from "react";
import './index.css';
import ThreeApartments from '../../../../../static/ThreeApartments.svg';
import SingleBuilding from '../../../../../static/SingleBuilding.svg';
import SingleBuildingBubble from '../../../../../static/SingleBuildingBubble.svg';

export default class FeaturesModule extends React.Component {
  render() {
    return (
      <div className="module-wrapper features-module">
        <div className="module-container features-module-container">
          <div className="feature-item">
            <div className="feature-item-image-container">
              <img className="feature-item-image three-apartments" src={ThreeApartments} alt="find your dream neighborhood" />
            </div>
            <div className="main-text feature-item-text">
                Search your dream neighborhood
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-item-image-container">
              <img className="feature-item-image single-building" src={SingleBuilding} alt="find a rent stabilized building" />
            </div>
            <div className="main-text feature-item-text">
                Save Rent Stabilized Units you like
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-item-image-container">
              <img className="feature-item-image single-building-bubble" src={SingleBuildingBubble} alt="find your home" />
            </div>
            <div className="main-text feature-item-text">
                Sign the lease and move in!
            </div>
          </div>
        </div>
      </div>
    );
  }
}
