import React from "react";
import './index.css';
import MonitorModule from './MonitorModule';
import DreamNeighborhoodModule from './DreamNeighborhoodModule';
import FeaturesModule from './FeaturesModule';
import SpendAndSaveModule from './SpendAndSaveModule';
import Footer from '../../Footer';

export default class HomeContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MonitorModule />
        <DreamNeighborhoodModule />
        <FeaturesModule />
        <SpendAndSaveModule />
        <Footer />
      </React.Fragment>
    );
  }
}
